<template>
	<div class="animated fadeIn">
		<div class="container">
			<user-data-form
				:form-import="form"
				:loading="loading"
				:errors-import="errors.errors"
				:user-information="data"
				:statuses="statuses"
				@requestData="requestUpdateUser" />
		</div>
	</div>
</template>
<script>

import User from '@/util/User';
import { STATUSES as statuses } from '@/settings/Statuses';
import { Validations as messages } from '@/translations';
import UserDataForm from './components/UserDataForm';

export default {
	name: 'UpdateUser',
	messages,
	components: {
		UserDataForm,
	},
	data() {
		return {
			user: new User(),
			statuses,
			alert: new this.$Alert(),
			form: {
				username: '',
				email: '',
				first_name: '',
				last_name: '',
				status: '',
				mobile_number: '',
				access_group: '',
				type: '',
				tfa_enabled: '',
			},
		};
	},
	computed: {
		response() {
			try {
				const { data } = this.user.data.response;
				return data;
			} catch (error) {
				return {};
			}
		},
		data: {
			get() {
				try {
					return this.response.data.attributes;
				} catch (error) {
					return {};
				}
			},
			set(payload) {
				this.response.data.attributes = { ...this.response.data.attributes, ...payload };
			},
		},
		errors() {
			return this.user.data.errors;
		},
		loading() {
			try {
				return this.user.data.loading;
			} catch (error) {
				return false;
			}
		},
		routeId() {
			return this.$route.params.userId || this.$route.params.corporateId || this.$route.params.customerId;
		},
	},
	watch: {
		routeId: () => {
			this.user.loadUser(this.routeId).then(() => {
				this.setFocus('username');
			});
		},
	},
	created() {
		this.user.loadUser(this.routeId).then(() => {
			this.setFocus('username');
		});
	},
	methods: {
		requestUpdateUser(payload) {
			this.user.updateUser(this.routeId, payload).then(() => {
				try {
					this.alert.toast('success', this.translate('user_updated'));
				} finally {
					if (this.$route.params.userId) {
						this.$router.push({ name: 'Users' });
					} else if (this.$route.params.corporateId) {
						this.$router.push({ name: 'Corporates' });
					} else if (this.$route.params.customerId) {
						this.$router.push({ name: 'Customers' });
					}
				}
			}).catch(() => { this.data = payload; });
		},
	},
};
</script>
